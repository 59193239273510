.border {
    border: 1px solid #cccccc;
}

.marg-0 {
    margin: 0 !important;
}

.marg-20 {
    margin: 20px;
}

.marg-20-bottom {
    margin-bottom: 20px;
}

.marg-10-top{
    margin-top: 10px;
}

.marg-10-left{
    margin-left: 10px;
}

.pad-0-top {
    padding-top: 0 !important;
}

.pad-20 {
    padding: 20px;
}

.pad-20-bottom {
    padding-bottom: 20px !important;
}

.pad-10-left {
    padding-left: 10px;
}

.rounded {
    border-radius: 3px !important;
}

.bg-blue {
    background: var(--blue);
}

.flex {
    display: flex;
}

.flex-1 {
    flex: 1
}

.color-blue {
    color: var(--blue)
}

.bg-white {
    background-color: white;
}

.center {
    text-align: center;
}

.font-large {
    font-size: 20px;
}

.font-small {
    font-size: 0.9rem;
}

.text-grey {
    color: #b8b3b3;
}

.text-white {
    color: #ffffff !important;
}

[v-cloak] {
    display: none;
}

.line-height {
    line-height: 1.4em;
}

.uppercase {
    text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
    .not-mobile {
        display: none !important;
    }
}
@media only screen and (min-width: 768px) {
    .mobile-only {
        display: none !important;
    }
}
