@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?i4fnzz');
    src:  url('../fonts/icomoon.eot?i4fnzz#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?i4fnzz') format('truetype'),
    url('../fonts/icomoon.woff?i4fnzz') format('woff'),
    url('../fonts/icomoon.svg?i4fnzz#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-play4:before {
    content: "\ea1c";
}
.icon-play3:before {
    content: "\ed7e";
}
.icon-filter:before {
    content: "\ee89";
}
.icon-menu:before {
    content: "\e902";
}
.icon-file-xml:before {
    content: "\e9bb";
}
.icon-bell:before {
    content: "\ea57";
}
.icon-calendar-week:before {
    content: "\ea66";
}
.icon-users5:before {
    content: "\eb0f";
}
.icon-search5:before {
    content: "\eb3a";
}
.icon-cog2:before {
    content: "\eb61";
}
.icon-medal:before {
    content: "\eba0";
}
.icon-docendo:before {
    content: "\ec49";
    color: #fff;
}
.icon-more2:before {
    content: "\ec5c";
}
.icon-tree5:before {
    content: "\ec64";
}
.icon-bookmark4:before {
    content: "\ecba";
}
.icon-question3:before {
    content: "\ed55";
}
.icon-cross2:before {
    content: "\ed6d";
}
.icon-cross3:before {
    content: "\ed6e";
}
.icon-checkmark4:before {
    content: "\ed72";
}
.icon-shuffle:before {
    content: "\edaf";
}
.icon-shuffle2:before {
    content: "\edb0";
}
.icon-split:before {
    content: "\edb3";
}
.icon-merge:before {
    content: "\edb4";
}
.icon-arrow-up4:before {
    content: "\edba";
}
.icon-arrow-right4:before {
    content: "\edbe";
}
.icon-arrow-down4:before {
    content: "\edc2";
}
.icon-arrow-left4:before {
    content: "\edc6";
}
.icon-arrow-up5:before {
    content: "\edc7";
}
.icon-arrow-right5:before {
    content: "\edc8";
}
.icon-arrow-down5:before {
    content: "\edc9";
}
.icon-arrow-left5:before {
    content: "\edca";
}
.icon-checkmark-circle:before {
    content: "\ee76";
}
.icon-download:before {
    content: "\e900";
}
.icon-home2:before {
    content: "\e901";
}
.icon-pencil:before {
    content: "\e90e";
}
.icon-file-upload:before {
    content: "\e9a7";
}
.icon-file-text2:before {
    content: "\e9ad";
}
.icon-folder-open:before {
    content: "\e9dc";
}
.icon-calculator2:before {
    content: "\ea18";
}
.icon-location:before {
    content: "\ea38";
}
.icon-clock:before {
    content: "\ea4d";
}
.icon-calendar2:before {
    content: "\ea60";
}
.icon-calendar4:before {
    content: "\ea62";
}
.icon-calendar5:before {
    content: "\ea63";
}
.icon-year-planning:before {
    content: "\ea64";
}
.icon-printer3:before {
    content: "\ea69";
}
.icon-bubble:before {
    content: "\eac4";
}
.icon-user:before {
    content: "\eaf7";
}
.icon-users:before {
    content: "\eaf8";
}
.icon-users3:before {
    content: "\eb01";
}
.icon-user3:before {
    content: "\eb08";
}
.icon-user-tie:before {
    content: "\eb0d";
}
.icon-enlarge2:before {
    content: "\eb3e";
}
.icon-shrink7:before {
    content: "\eb49";
}
.icon-lock5:before {
    content: "\eb55";
}
.icon-unlocked2:before {
    content: "\eb56";
}
.icon-bin:before {
    content: "\ebfd";
}
.icon-clipboard2:before {
    content: "\ec51";
}
.icon-upload7:before {
    content: "\ec8a";
}
.icon-download10:before {
    content: "\ec8f";
}
.icon-heart7:before {
    content: "\ecef";
}
.icon-notification2:before {
    content: "\ed52";
}
.icon-enter3:before {
    content: "\ed7a";
}
.icon-arrow-up:before {
    content: "\edb7";
}
.icon-arrow-down:before {
    content: "\edbf";
}
