@keyframes word-fade{
    0% {opacity: 0}
    20% {opacity: 1}
    80% {opacity: 1}
    100% {opacity: 0}
}

@keyframes dot-fade{
    0% {opacity: 0}
    20% {opacity: 0.5}
    80% {opacity: 0.5}
    100% {opacity: 0}
}

.fade-enter{
    opacity: 0;
}

.fade-enter-active{
    transition: opacity 0.2s;
    animation: slide-in 0.2s ease-out;
}

.fade-leave-active{
    transition: opacity .2s;
    opacity: 0;
    display: none;
}

@keyframes slide-in {
    0% {transform: translateY(20px)}
    100% {transform: translateY(0)}
}
